import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
  SiteTitle,
  SiteHeaderContent,
  SiteHeaderStyles
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = ({data}) => (
  <IndexLayout>
    <Helmet>
      <title>Jihi | { data.about.frontmatter.title } Spread Joy</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <div
          css={[outer, SiteHeader, SiteHeaderStyles]}
          className="site-header-background"
        >
        <div css={inner}>
          <SiteNav isHome />
          <SiteHeaderContent className="site-header-content">
            <SiteTitle className="site-title">
              { data.about.frontmatter.title }
            </SiteTitle>
          </SiteHeaderContent>
        </div>
      </div>

      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullContent className="post-full-content">
              <div className="post-content" dangerouslySetInnerHTML={{ __html: data.about.html }} />
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;

export const query = graphql`
  query {
    about: markdownRemark(fields: {template_key: {eq: "page.about"}}) {
      html
      frontmatter {
        title
      }
    }
  }
`;
